<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <div
        v-if="showCollapse"
        class="avue-breadcrumb"
      >
        <!--<i class="icon-navicon avue-breadcrumb_collapse"-->
        <!--:class="[{ 'avue-breadcrumb_collapse&#45;&#45;right': isCollapse }]"-->
        <!--@click="setCollapse"></i>-->
        <div
          class="collapse_icon"
          :class="[{ 'avue-breadcrumb_collapse--right': isCollapse },]"
          @click="setCollapse"
        />
      </div>
    </div>
    <h1 class="top-bar__title">
      <!--<div class="top-bar__item top-bar__item--show"
           v-if="showMenu">
        <top-menu></top-menu>
      </div>-->
      <span
        v-if="showSearch"
        class="top-bar__item"
      >
        <top-search />
      </span>
    </h1>
    <div class="top-bar__right">
      <!--<el-tooltip v-if="showDebug"
                  effect="dark"
                  :content="logsFlag?'没有错误日志':`${logsLen}条错误日志`"
                  placement="bottom">
        <div class="top-bar__item">
          <top-logs></top-logs>
        </div>
      </el-tooltip>-->
      <el-tooltip
        v-if="showLock"
        effect="dark"
        content="锁屏"
        placement="bottom"
      >
        <div class="top-bar__item">
          <top-lock />
        </div>
      </el-tooltip>
      <el-tooltip
        v-if="showFullScren"
        effect="dark"
        :content="isFullScren?'退出全屏':'全屏'"
        placement="bottom"
      >
        <div class="top-bar__item">
          <i
            :class="isFullScren?'icon-tuichuquanping':'icon-quanping'"
            @click="handleScreen"
          />
        </div>
      </el-tooltip>
      <el-dropdown>
        <span class="el-dropdown-link">
          <!--{{userInfo.username}}-->用户
          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <router-link to="/">
              首页
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item
            divided
            @click.native="logout"
          >
            退出系统
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!--<top-setting></top-setting>-->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { fullscreenToggel, listenfullscreen } from '@/assets/js/util';
import topLock from './top-lock';
import topMenu from './top-menu';
import topSearch from './top-search';
import topBreadcrumb from './top-breadcrumb';
import topTheme from './top-theme';
import topSetting from './top-setting';

export default {
  name: 'Top',
  components: {
    topLock,
    topMenu,
    topSearch,
    topBreadcrumb,
    topTheme,
    topSetting,
  },
  filters: {},
  data() {
    return {

    };
  },
  created() {},
  mounted() {
    listenfullscreen(this.setScreen);
  },
  computed: {
    ...mapState({
      showDebug: (state) => state.common.showDebug,
      showColor: (state) => state.common.showColor,
      showTheme: (state) => state.common.showTheme,
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showSearch: (state) => state.common.showSearch,
      showMenu: (state) => state.common.showMenu,
    }),
    ...mapGetters([
      'userInfo',
      'isFullScren',
      'tagWel',
      'tagList',
      'isCollapse',
      'tag',
      'logsLen',
      'logsFlag',
    ]),
  },
  methods: {
    handleScreen() {
      fullscreenToggel();
    },
    setCollapse() {
      this.$store.commit('SET_COLLAPSE');
    },
    setScreen() {
      this.$store.commit('SET_FULLSCREN');
    },
    logout() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' });
          localStorage.removeItem('isLoginFlag');
        });
      });
    },
  },
};

</script>
<style lang="scss">
.avue-top {
  height: 54px !important;
  line-height: 37px;

  .avue-breadcrumb_collapse {
    line-height: 57px;
  }

  .collapse_icon {
    background: url('../../../assets/images/order/arrow.png') no-repeat center center;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
    margin-top: 18px;
    cursor: pointer;
  }

  .avue-breadcrumb_collapse--right {
    transform: rotate(180deg);
    margin-left: 8px;
  }
}

</style>
