import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'babel-polyfill';
// import 'classlist-polyfill';
// import axios from '@/api/axios'
import axios from 'axios';
import VueAxios from 'vue-axios';
import './permission'; // 权限
// import './error'; // 日志
// import 'avue-plugin-transfer/packages'; // 引入avue-plugin-transfer插件
// import 'avue-plugin-ueditor/packages' //引入avue-plugin-ueditor插件(如果要兼容ie自行换掉富文本编辑器，此款插件不兼容ie)
import Avue from '@smallwei/avue';
import VueClipboard from 'vue-clipboard2';
import VueQuillEditor from 'vue-quill-editor';
import store from '@/store';
import './assets/icon/iconfont.css';
import { loadStyle } from '@/assets/js/util';
// import * as urls from '@/assets/js/env';
// import { iconfontUrl, iconfontVersion } from '@/assets/js/env';
// import * as filters from '@/assets/js/filters' // 全局filter
import '@/assets/css/common.scss';

import '@/assets/css/template.scss';
// // 引入avue的包
// // 引入avue的样式文件
// import '@smallwei/avue/lib/index.css';

// 插件 json 展示
// import ViewUI from 'view-design';
// import VuePreview from 'vue-preview';

// import style
// import 'view-design/dist/styles/iview.css';
// import 'vue2-toast/lib/toast.css';
// import Toast from 'vue2-toast';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
// import Viewer from 'v-viewer';
import router from './router/router';
import App from './App';
// import 'viewerjs/dist/viewer.css';
import ZButton from '@/components/common/ZButton.vue';

Vue.use(ElementUI);
// Vue.use(Viewer, {
//   defaultOptions: {
//     zIndex: 9999,
//   },
// });
Vue.use(VueQuillEditor);
// Vue.use(Toast);
// Vue.use(VuePreview);
// Vue.use(ViewUI);
Vue.use(ZButton);
Vue.component('z-button', ZButton);

// 百度富文本编辑器
// import './static/Ueditor/ueditor.config.js'
// import './static/Ueditor/ueditor.all.js'
// import './static/Ueditor/lang/zh-cn/zh-cn.js'

// //源文件包
// import '../packages/index.js';
// import '../packages/theme-chalk/src/index.scss';

Vue.use(Avue, { menuType: 'text' });

Vue.use(router);

Vue.prototype.URL_PREFIX = window.origin === 'https://ods.zydmall.com'
  ? 'https://zydmall-files.oss-cn-shenzhen.aliyuncs.com'
  : 'https://zydmall-test.oss-cn-shenzhen.aliyuncs.com';

Vue.use(VueClipboard);

Vue.use(VueAxios, axios);

// Object.keys(urls).forEach((key) => {
//   Vue.prototype[key] = urls[key];
// });

// Object.keys(filters).forEach(key => {
//     Vue.filter(key, filters[key])
// })

// iconfontVersion.forEach(ele => {
//     loadStyle(iconfontUrl.replace('$key', ele))
// })

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
