import Vue from 'vue';
import {
  Notification,
} from 'element-ui';
import Stomp from 'stompjs';
import { validatenull } from './validate';

// 表单序列化
export const serialize = (data) => {
  const list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join('&');
};
export const getObjType = (obj) => {
  const { toString } = Object.prototype;
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  };
  if (obj instanceof Element) {
    return 'element';
  }
  return map[toString.call(obj)];
};
/**
     * 对象深拷贝
     */
export const deepClone = (data) => {
  const type = getObjType(data);
  let obj;
  if (type === 'array') {
    obj = [];
  } else if (type === 'object') {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === 'object') {
    for (const key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};
/**
     * 判断路由是否相等
     */
export const diff = (obj1, obj2) => {
  delete obj1.close;
  const o1 = obj1 instanceof Object;
  const o2 = obj2 instanceof Object;
  if (!o1 || !o2) { /*  判断不是对象  */
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (const attr in obj1) {
    const t1 = obj1[attr] instanceof Object;
    const t2 = obj2[attr] instanceof Object;
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
};
/**
     * 设置灰度模式
     */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = `${document.body.className} grayMode`;
  } else {
    document.body.className = document.body.className.replace(' grayMode', '');
  }
};
/**
     * 设置主题
     */
export const setTheme = (name) => {
  document.body.className = name;
};

/**
 *加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key,
  } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele) => {
      const data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      const iv = key;
      // 加密
      const encrypted = CryptoJS.MD5(
        data,
        key, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding,
        },
      );
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};
/**
     * esc监听全屏
     */
export const listenfullscreen = (callback) => {
  function listen() {
    callback();
  }

  document.addEventListener('fullscreenchange', () => {
    listen();
  });
  document.addEventListener('mozfullscreenchange', () => {
    listen();
  });
  document.addEventListener('webkitfullscreenchange', () => {
    listen();
  });
  document.addEventListener('msfullscreenchange', () => {
    listen();
  });
};
/**
     * 浏览器判断是否全屏
     */
export const fullscreenEnable = () => document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
};
/**
     * 浏览器退出全屏
     */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen();
  }
};
/**
     * 递归寻找子类的父类
     */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length != 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i];
        }
        if (menu[i].children[j].children.length != 0) {
          return findParent(menu[i].children[j].children, id);
        }
      }
    }
  }
};
/**
     * 判断2个对象属性和值是否相等
     */

/**
 * 动态插入css
 */

export const loadStyle = (url) => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
/**
     * 判断路由是否相等
     */
export const isObjectValueEqual = (a, b) => {
  let result = true;
  Object.keys(a).forEach((ele) => {
    const type = typeof (a[ele]);
    if (type === 'string' && a[ele] !== b[ele]) result = false;
    else if (type === 'object' && JSON.stringify(a[ele]) !== JSON.stringify(b[ele])) result = false;
  });
  return result;
};
/**
     * 根据字典的value显示label
     */
export const findByvalue = (dic, value) => {
  let result = '';
  if (validatenull(dic)) return value;
  if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
    let index = 0;
    index = findArray(dic, value);
    if (index != -1) {
      result = dic[index].label;
    } else {
      result = value;
    }
  } else if (value instanceof Array) {
    result = [];
    let index = 0;
    value.forEach((ele) => {
      index = findArray(dic, ele);
      if (index != -1) {
        result.push(dic[index].label);
      } else {
        result.push(value);
      }
    });
    result = result.toString();
  }
  return result;
};
/**
     * 根据字典的value查找对应的index
     */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i;
    }
  }
  return -1;
};
/**
     * 生成随机len位数字
     */
export const randomLenNum = (len, date) => {
  let random = '';
  random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4);
  if (date) random += Date.now();
  return random;
};
/**
     * 打开小窗口
     */
export const openWindow = (url, title, w, h) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const left = ((width / 2) - (w / 2)) + dualScreenLeft;
  const top = ((height / 2) - (h / 2)) + dualScreenTop;
  const newWindow = window.open(url, title, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`);

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
};

export const calcDate = (date1, date2) => {
  const date3 = date2 - date1;

  const days = Math.floor(date3 / (24 * 3600 * 1000));

  const leave1 = date3 % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000));

  const leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000));

  const leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  const seconds = Math.round(date3 / 1000);
  return {
    leave1,
    leave2,
    leave3,
    days,
    hours,
    minutes,
    seconds,
  };
};

/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = 'yyyy-MM-dd hh:mm:ss';
  if (date != 'Invalid Date') {
    const o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      S: date.getMilliseconds(), // millisecond
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1,
        (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(format)) {
        format = format.replace(RegExp.$1,
          RegExp.$1.length === 1 ? o[k]
            : (`00${o[k]}`).substr((`${o[k]}`).length));
      }
    }
    return format;
  }
  return '';
}

/** *
 * 根据key获得对象
 * @param key
 * @returns {any}
 */
export function localStorageGetObj(key) {
  return JSON.parse(localStorage.getItem(key));
}

/** *
 * 根据key设置对象
 * @param key
 * @returns {any}
 */
export function localStorageSetObj(key, val) {
  localStorage.setItem(key, JSON.stringify(val));
}

/** *
 * 处理日期
 */
export function dealTime(param) {
  const checkTime = (i) => {
    if (i < 10) {
      i = `0${i}`;
    }
    return i;
  };
  const date = new Date(param);
  const dateTime = `${date.getFullYear()}-${checkTime(date.getMonth() + 1)}-${checkTime(date.getDate())}`;
  return dateTime;
}

/** *
 * 处理开始日期
 */
export function dealBeginTime(param) {
  return `${dealTime(param)} 00:00:00`;
}

/** *
 * 处理结束日期
 */
export function dealEndTime(param) {
  return `${dealTime(param)} 23:59:59`;
}

/** *
 * 判断日期范围是否大于day天
 */
export function checkDayRange(beginTime, endTime, day) {
  if (!beginTime || !endTime) {
    return false;
  }
  const startD = new Date(Date.parse(beginTime.replace(/-/g, '/')));
  const endD = new Date(Date.parse(endTime.replace(/-/g, '/')));
  const days = parseInt((endD.getTime() - startD.getTime()) / (1000 * 60 * 60 * 24));
  if (days > day) {
    return false;
  }
  return true;
}

// /**
//  * 判断是否空
//  */

export function checkNull(obj) {
  if (obj === undefined || obj === null) return true;
  if (Array.isArray(obj)) {
    return obj.length <= 0;
  }
  // delete  obj['__ob__']
  try {
    return !!(obj === '' || JSON.stringify(obj) === JSON.stringify({}));
  } catch (e) {
    return false;
  }
}

export function copyWord(text) {
  const oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand('Copy');
  oInput.style.display = 'none';
}

export const getUploadMessage = (url, param) => {
  Notification.closeAll();
  Notification({
    position: 'bottom-right',
    dangerouslyUseHTMLString: true,
    message: '<span class="notify_title"><i class="el-icon-bell" style="margin-right: 4px"></i>下载消息</span><br>'
      + `<a class="notify_text" target="_blank" href="${url}">${param}已下载完成</a>`,
    duration: 0,
  });
};
let stompClient = null;
const iProduction = true;

// export function connect() {
//   var wsUrl = iProduction ? 'wss://mq.zxyzt.cn/ws' : 'ws://10.10.10.175:15674/ws',
//     websocket = new WebSocket(wsUrl);
//   let connect_data = {
//     "login": "stomp",
//     "passcode": "zyd#1234",
//     //虚拟主机，默认“/”
//     "host": "/web/stomp",
//     "heart-beat": "0,0"
//   }
//   // 开启socket连接
//   // var socket = new SockJS('/socket');
//   stompClient = Stomp.over(websocket);
//   stompClient.connect(connect_data, function(frame) {
//     // stompClient.subscribe("/exchange/amq.fanout/red.storm", function(data) {
//     //   let msg = JSON.parse(data.body);
//     //   getUploadMessage(msg.url,msg.title)
//     // });
//     stompClient.subscribe("/exchange/amq.fanout/red.storm", function(data) {
//         let msg = JSON.parse(data.body);
//         getUploadMessage(msg.url,msg.title)
//       });
//   });
// }
export function connect() {
  console.log('id', localStorage.getItem('userid'));
  const socket_path = 'wss://mq.zydmall.com/ws';

  if (typeof WebSocket === 'undefined') {
    console.log('不支持websocket');
  }
  // 初始化 ws 对象
  const ws = new WebSocket(socket_path);
  // 获得Stomp client对象
  const client = Stomp.over(ws);
  // 定义连接成功回调函数
  const on_connect = function (x) {
    client.subscribe('/exchange/message.talking.mgr/msgpullmgr.490', (data) => {
      const { body } = data; const messList = JSON.parse(body);
      console.log('messList', messList);
    });
  };
  // 定义错误时回调函数
  const on_error = function () {
    console.log('error');
  };
  // 连接RabbitMQ
  client.connect('admin', 'zyd1234', on_connect, on_error, '/');
  console.log(`>>>连接上${socket_path}`);
}
// 断开socket连接
export function disconnect() {
  if (stompClient != null) {
    stompClient.disconnect();
    stompClient = null;
    clearInterval(MyMarAbb2);
  }
  console.log('Disconnected');
}
let socket;
export function openSocket() {
  if (typeof (WebSocket) === 'undefined') {
    console.log('您的浏览器不支持WebSocket');
  } else {
    console.log('sadasdasd');
    console.log('您的浏览器支持WebSocket');
    // 实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
    // 等同于socket = new WebSocket("ws://localhost:8888/xxxx/im/25");
    // var socketUrl="${request.contextPath}/im/"+$("#userId").val();
    let socketUrl = 'http://10.88.20.14:6052/websocket/custExcel/l_490';
    socketUrl = socketUrl.replace('https', 'ws').replace('http', 'ws');
    console.log(socketUrl);
    if (socket != null) {
      socket.close();
      socket = null;
    }
    socket = new WebSocket(socketUrl);
    // 打开事件
    socket.onopen = function () {
      console.log('websocket已打开');
      // socket.send("这是来自客户端的消息" + location.href + new Date());
    };
    // 获得消息事件
    socket.onmessage = function (msg) {
      console.log(msg.data);
      // 发现消息进入    开始处理前端触发逻辑
    };
    // 关闭事件
    socket.onclose = function () {
      console.log('websocket已关闭');
    };
    // 发生了错误事件
    socket.onerror = function () {
      console.log('websocket发生了错误');
    };
  }
}
export function sendMessage() {
  if (typeof (WebSocket) === 'undefined') {
    console.log('您的浏览器不支持WebSocket');
  } else {
    console.log('您的浏览器支持WebSocket');
    console.log(`{"toUserId":"${$('#toUserId').val()}","contentText":"${$('#contentText').val()}"}`);
    const message = {
      toUserId: 111,
      contentText: '22222',
    };
    socket.send(JSON.stringify(message));
  }
}

// 获取cookie
export function getCookies(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1);
    if (c.indexOf(name) != -1) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
// 向‘/app/change-notice’服务端发送消息
// function sendName() {
//     var value = document.getElementById('name').value;
//     stompClient.send("/exchange/amq.fanout/red.storm", {},value);
// }
