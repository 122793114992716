import axios from 'axios';
import { Message } from 'element-ui';
import NProgress from 'nprogress';
import store from '@/store';
import router from '@/router/router';
import 'nprogress/nprogress.css';

const instance = axios.create({
  timeout: 60000,
});

instance.interceptors.request.use(
  (config) => {
    NProgress.start(); // start progress bar

    config.baseURL = process.env.VUE_APP_GATEWAY;
    return config;
  },
  (error) => Promise.reject(error),
);
const ERR_OK = 0;
const LOGOUT = -3;
const LOGOUT2 = -95;
const LOGOUT3 = 3;
instance.interceptors.response.use((response) => {
  NProgress.done(); // start progress bar
  const { code, data, message } = response.data;
  const isNewType = response.config.isNewType ? response.config.isNewType : false
  if (response.config.responseType === 'blob') {
    return response;
  }
  if (code !== ERR_OK) {
    if (code === LOGOUT || code === LOGOUT2 || code === LOGOUT3) {
      Message.error('请先登录');
      store.dispatch('FedLogOut').then(() => {
        router.push({
          path: '/login',
        });
      });
    } else {
      if (message) {
        Message.error(message);
      }
    }
    return Promise.reject(response.data);
  }
  let res = data
  if(isNewType) {
    res = response.data
  }
  return res;
}, (error) => Promise.reject(error));

export default instance;
