import { getStore, setStore } from '@/assets/js/store';
import { diff } from '@/assets/js/util';
import website from '@/const/website';

const { isFirstPage } = website;
const tagWel = website.fistPage;
const tagObj = {
  label: '', // 标题名称
  value: '', // 标题的路径
  params: '', // 标题的路径参数
  query: '', // 标题的参数
  group: [], // 分组
};

// 处理首个标签
function setFistTag(list) {
  if (list.length === 1) {
    list[0].close = false;
  } else {
    list.forEach((ele) => {
      if (ele.value === tagWel.value && isFirstPage === false) {
        ele.close = false;
      } else {
        ele.close = true;
      }
    });
  }
}

// 处理标签顺序
function handleTagOrder(list) {
  const new_data = list.filter((element, index) => element.label != '首页');
  new_data.unshift(list.find((x) => x.label === '首页'));
  return new_data;
}
const navs = {
  state: {
    tagList: getStore({ name: 'tagList' }) || [],
    tag: getStore({ name: 'tag' }) || tagObj,
    // 需要被缓存的组件名
    cacheTags: getStore({ name: 'tagList' }) && getStore({ name: 'tagList' }).length ? getStore({ name: 'tagList' }).map((item) => item.comName) : [],
    tagWel,
  },
  actions: {},
  mutations: {
    ADD_TAG: (state, action) => {
      state.tag = action;
      setStore({ name: 'tag', content: state.tag, type: 'session' });

      if (state.tagList.some((ele) => diff(ele, action))) return;
      if (!state.tagList.map((item) => item.label).includes(state.tag.label)) {
        state.tagList.push(action);
      } else {
        // state.tagList[state.tagList.findIndex((item)=>{
        //   return item.label === state.tag.label
        // })].params = state.tag.params
      }
      setFistTag(state.tagList);
      setStore({ name: 'tagList', content: state.tagList, type: 'session' });
    },
    DEL_TAG: (state, action) => {
      state.tagList = state.tagList.filter((item) => !diff(item, action));
      setFistTag(state.tagList);
      setStore({ name: 'tagList', content: state.tagList, type: 'session' });
    },
    DEL_ALL_TAG: (state) => {
      state.tagList = [state.tagWel];
      setStore({ name: 'tagList', content: state.tagList, type: 'session' });
    },
    DEL_TAG_OTHER: (state) => {
      const firstTag = {
        label: '首页',
        value: '/home',
        comName: 'HomeView',
        params: {},
        query: {},
        group: [],
      };
      if (state.tag.label === '首页' && state.tagList.length) {
        state.tagList = [firstTag];
        setStore({ name: 'tagList', content: state.tagList, type: 'session' });
        return;
      }
      state.tagList = state.tagList.filter((item) => item.value === state.tag.value);
      state.tagList.unshift(firstTag);
      setFistTag(state.tagList);
      setStore({ name: 'tagList', content: state.tagList, type: 'session' });
    },
    REMOVE_TAG_CACHE: (state, removeItem) => {
      state.cacheTags = state.cacheTags.filter((item) => item !== removeItem);
    },
  },
};
export default navs;
