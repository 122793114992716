/**
 * 全站权限配置
 *
 */
import NProgress from 'nprogress'; // progress bar
import router from './router/router';
import store from './store';
import { validatenull } from '@/assets/js/validate';
// import { getToken } from '../public/js/auth';
import 'nprogress/nprogress.css';
// progress bar style
NProgress.configure({ showSpinner: false });
const { lockPage } = store.getters.website; // 锁屏页
router.beforeEach((to, from, next) => {
  // 缓冲设置
  // if (to.meta.keepAlive === true && store.state.tags.tagList.some(ele => {
  if (store.state.tags.tagList.some((ele) => ele.value === to.fullPath)) {
    to.meta.$keepAlive = true;
  } else {
    NProgress.start();
    if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
      to.meta.$keepAlive = true;
    } else {
      to.meta.$keepAlive = false;
    }
  }
  const meta = to.meta || {};
  // if (getToken()) {
  //   if (store.getters.isLock && to.path != lockPage) {
  //     next({ path: lockPage })
  //   } else if (to.path === '/login') {
  //     next({ path: '/' })
  //   } else {
  //     if (store.getters.sessionid.length === 0) {
  //       store.dispatch('GetUserInfo').then(res => {
  //         next({ ...to, replace: true })
  //       }).catch(() => {
  //         store.dispatch('FedLogOut').then(() => {
  //           next({ path: '/login' })
  //         })
  //       })
  //     } else {
  //       const value = to.query.src || to.fullPath
  //       const label = to.query.name || to.name
  //       if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
  //         store.commit('ADD_TAG', {
  //           label: label,
  //           value: value,
  //           params: to.params,
  //           query: to.query,
  //           group: router.$avueRouter.group || []
  //         })
  //       }
  //       next()
  //     }
  //   }
  //   const value = to.query.src || to.fullPath
  //   const label = to.query.name || to.name
  //   if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
  //     store.commit('ADD_TAG', {
  //       label: label,
  //       value: value,
  //       params: to.params,
  //       query: to.query,
  //       group: router.$avueRouter.group || []
  //     })
  //   }
  //   next()
  // } else {
  //   if (meta.isAuth === false) {
  //     next()
  //   } else {
  //     next('/login')
  //   }
  // }
  const value = to.query.src || to.fullPath;
  const label = to.query.name || to.name;
  if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
    store.commit('ADD_TAG', {
      label,
      value,
      comName: to.meta.comName,
      params: to.params,
      query: to.query,
      group: router.$avueRouter.group || [],
    });
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
  const title = store.getters.tag.label;
  router.$avueRouter.setTitle(title);
});
