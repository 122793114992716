<template>
  <div
    class="avue-contail"
    :class="{'avue--collapse':isCollapse}"
  >
    <!--<div class="avue-header">-->
    <!--&lt;!&ndash; 顶部导航栏 &ndash;&gt;-->
    <!--<top />-->
    <!--</div>-->
    <div class="avue-layout">
      <div class="avue-left">
        <!-- 左侧导航栏 -->
        <sidebar />
      </div>
      <div class="avue-main">
        <!-- 顶部标签卡 -->
        <tags />
        <!-- 主体视图层 -->
        <el-scrollbar style="height:100%">
          <!-- {{cacheTags}} -->
          <keep-alive :include="cacheTags">
            <router-view
              v-if="$route.meta.$keepAlive"
              class="avue-view"
            />
          </keep-alive>
          <router-view
            v-if="!$route.meta.$keepAlive"
            class="avue-view"
          />
        </el-scrollbar>
      </div>
    </div>
    <!-- <el-footer class="avue-footer">
      <img src="/svg/logo.svg"
           alt=""
           class="logo">
      <p class="copyright">© 2018 Avue designed by smallwei</p>
    </el-footer> -->
    <div
      class="avue-shade"
      @click="showCollapse"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import tags from './tags';
import top from './top';
import sidebar from './sidebar';
import { validatenull } from '@/assets/js/validate';
import { calcDate } from '@/assets/js/util.js';
import { getStore } from '@/assets/js/store.js';

export default {
  name: 'Index',
  components: {
    top,
    tags,
    sidebar,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: '',
      // 缓存的组件名
      // includeCacheGroup: [],
    };
  },
  watch: {
    // 首次添加tab标签的时候，$keepAlive都是false（未去查明真相），临时改为true，否则首次切换到别的标签无法缓存
    $route: {
      handler(to, from) {
        if (!this.$route.meta.$keepAlive) {
          this.$route.meta.$keepAlive = true;
        }
      },
      deep: true,
    },
    // tagList: {
    //   handler(to, from) {
    //     this.includeCacheGroup = [];
    //     to.map((item) => {
    //       this.includeCacheGroup.push(item.comName);
    //     });
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    // 实时检测刷新token
    // this.refreshToken();
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['isLock', 'isCollapse', 'website', 'tagList', 'userInfo']),
    ...mapState({
      cacheTags: (state) => state.tags.cacheTags,
    }),
  },
  props: [],
  methods: {
    showCollapse() {
      this.$store.commit('SET_COLLAPSE');
    },

    // 屏幕检测
    init() {
      this.$store.commit('SET_SCREEN', this.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', this.getScreen());
        }, 0);
      };
    },
    getScreen() {
      const width = document.body.clientWidth;
      if (width >= 1200) {
        return 3; // 大屏幕
      }
      if (width >= 992) {
        return 2; // 中屏幕
      }
      if (width >= 768) {
        return 1; // 小屏幕
      }
      return 0; // 超小屏幕
    },
    // 实时检测刷新token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: 'token',
          debug: true,
        });
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (!(date.seconds >= this.website.tokenTime) && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch('RefeshToken')
            .then(() => {
              clearInterval(this.refreshTime);
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, 3000);
    },
    // 刷新当前页面
    reload() {
      const { path } = this.$route;
      this.$router.push({
        path: '/system/redirect',
        query: {
          path,
        },
      });
      const tag = this.tagList.find((item) => item.comName === 'redirect');
      this.$store.commit('DEL_TAG', tag);
    },
  },
};

</script>
