<template>
  <el-autocomplete
    v-model="value"
    class="top-search"
    popper-class="my-autocomplete"
    :fetch-suggestions="querySearch"
    placeholder="请输入搜索内容"
    @select="handleSelect"
  >
    <template slot-scope="{ item }">
      <i :class="[item[iconKey],'icon']" />
      <div class="name">
        {{ item[labelKey] }}
      </div>
      <p class="addr">
        {{ item[pathKey] }}
      </p>
    </template>
  </el-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../sidebar/config.js';

export default {
  data() {
    return {
      config,
      value: '',
      menuList: [
        {
          id: 1000,
          parentId: -1,
          children: [

          ],
          icon: 'el-icon-s-shop',
          name: '产品分类',
          spread: false,
          path: '/product/productKind',
          component: 'Layout',
          authority: null,
          redirect: null,
          keepAlive: '0',
          code: null,
          type: '0',
          label: '产品分类',
          sort: 0,
        },
        {
          id: 2000,
          parentId: -1,
          children: [

          ],
          icon: 'icon-quanxianguanli',
          name: '系统配置',
          spread: false,
          path: '/system/regionalManagement',
          component: 'views/system/regionalManagement',
          authority: null,
          redirect: null,
          keepAlive: '0',
          code: null,
          type: '0',
          label: '系统配置',
          sort: 0,
        },
      ],
    };
  },

  watch: {
    menu() {
      this.getMenuList();
    },
  },
  created() {
    const menuList = [
      {
        id: 1000,
        icon: 'el-icon-s-home',
        name: '首页',
        spread: false,
        path: '/home',
        component: 'views/home/HomeView',
        authority: null,
        redirect: null,
        keepAlive: '0',
        code: null,
        type: '0',
        label: '首页',
        sort: 0,
      },
      {
        id: 2000,
        parentId: -1,
        children: [
          {
            id: 2100,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-s-shop',
            name: '产品分类',
            spread: false,
            path: '/product/productKind',
            component: 'views/product/productKind',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '产品分类',
            sort: 1,
          },
          {
            id: 2200,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-s-cooperation',
            name: '产品品牌',
            spread: false,
            path: '/product/productBrand',
            component: 'views/product/productBrand',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '产品品牌',
            sort: 1,
          },
          {
            id: 2300,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-s-management',
            name: '产品系列',
            spread: false,
            path: '/product/productSeries',
            component: 'views/product/productSeries',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '产品系列',
            sort: 1,
          },
          {
            id: 2400,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-s-order',
            name: '产品规格',
            spread: false,
            path: '/product/productSpecification',
            component: 'views/product/productSpecification',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '产品规格',
            sort: 1,
          },
          {
            id: 2500,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-s-opportunity',
            name: '产品列表',
            spread: false,
            path: '/product/productManage',
            component: 'views/product/productManage',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '产品列表',
            sort: 1,
          },
          {
            id: 2600,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-picture',
            name: '产品图库',
            spread: false,
            path: '/product/productGallery',
            component: 'views/product/productGallery',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '产品图库',
            sort: 1,
          },
          {
            id: 2700,
            parentId: 2000,
            children: [

            ],
            icon: 'el-icon-s-open',
            name: '素材管理',
            spread: false,
            path: '/product/productSamplebank',
            component: 'views/product/productSamplebank',
            authority: null,
            redirect: null,
            keepAlive: '0',
            code: null,
            type: '0',
            label: '素材管理',
            sort: 1,
          },
        ],
        icon: 'el-icon-s-goods',
        name: '产品管理',
        spread: false,
        path: '/product',
        component: 'Layout',
        authority: null,
        redirect: null,
        keepAlive: '0',
        code: null,
        type: '0',
        label: '产品管理',
        sort: 0,
      },
    ];
    // this.getMenuList();
  },
  computed: {
    labelKey() { return this.website.menu.props.label || this.config.propsDefault.label; },
    pathKey() { return this.website.menu.props.path || this.config.propsDefault.path; },
    iconKey() { return this.website.menu.props.icon || this.config.propsDefault.icon; },
    childrenKey() { return this.website.menu.props.children || this.config.propsDefault.children; },
    ...mapGetters(['menu', 'website']),
  },
  methods: {
    getMenuList() {
      const findMenu = (list) => {
        for (let i = 0; i < list.length; i++) {
          const ele = { ...list[i] };
          if (ele[this.childrenKey]) findMenu(ele[this.childrenKey]);
          delete ele[this.childrenKey];
          this.menuList.push(ele);
        }
      };
      this.menuList = [];
      findMenu(this.menu);
    },
    querySearch(queryString, cb) {
      const restaurants = this.menuList;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => (restaurant.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    },
    handleSelect(item) {
      this.value = '';
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          name: item[this.labelKey],
          src: item[this.pathKey],
        }),
        query: item.query,
      });
    },
  },

};
</script>

<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;
    .icon {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
    .name {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;
    }
    .addr {
      padding-top: 5px;
      width: 100%;
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
