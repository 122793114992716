import Layout from '@/views/index/';

export default [{
  path: '/login',
  name: '登录页',
  component: () => import(/* webpackChunkName: "login" */ '@/views/login/index'),
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false,
  },
},
{
  path: '/404',
  component: () => import(/* webpackChunkName: "404" */ '@/components/error-page/404'),
  name: '404',
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false,
  },

},
{
  path: '/403',
  component: () => import(/* webpackChunkName: "403" */ '@/components/error-page/403'),
  name: '403',
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false,
  },
},
{
  path: '/500',
  component: () => import(/* webpackChunkName: "500" */ '@/components/error-page/500'),
  name: '500',
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false,
  },
},
{
  path: '/',
  name: '主页',
  redirect: '/home',
},
];
