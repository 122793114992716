<template>
  <el-breadcrumb
    separator="/"
    class="avue-breadcrumb-list"
  >
    <template v-for="(item,index) in tagCurrent">
      <el-breadcrumb-item
        :key="index"
        :to="{ path: item.value }"
      >
        {{ item.label }}
      </el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Breadcrumb',
  data() {
    return {};
  },
  watch: {},
  created() { },
  mounted() { },
  computed: {
    ...mapGetters(['tagCurrent']),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
