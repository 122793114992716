<template>
  <div id="app">
    <router-view />
    <div style="position: fixed;right: 0;bottom: 0;font-size: .7rem;color: darkgray; display: none;">
      UPDATED:
      {{ VUE_APP_BUILD_DATE || '-' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      VUE_APP_BUILD_DATE: process.env.VUE_APP_BUILD_DATE,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {

  },
};
</script>
<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  body{
    overflow: hidden;
  }
</style>
