import Vue from 'vue';
import VueRouter from 'vue-router';
import PageRouter from './page';
import ViewsRouter from './views';
import AvueRouter from './avue-router';
import Store from '@/store';
import { getUserInfo } from '@/api/login';
import { checkNull } from '@/assets/js/util';

Vue.use(VueRouter);

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const Router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (from.meta.keepAlive) {
      from.meta.savedPosition = document.body.scrollTop;
    }
    return {
      x: 0,
      y: to.meta.savedPosition || 0,
    };
  },
  // routes: [].concat([])
  routes: [...PageRouter, ...ViewsRouter],

});

AvueRouter.install(Router, Store);
Router.$avueRouter.formatRoutes(Store.state.user.menu, true);
Router.beforeEach((to, from, next) => {
  if (checkNull(Store.getters.userInfo) && to.path != '/login') {
    getUserInfo().then((response) => {
      // const { data, code } = res.data;
      if (response) {
        Store.commit('SET_USERIFNO', response);
        localStorage.setItem('menus', response.menus);
        next();
      } else {
        const isLogin = !!localStorage.isLoginFlag;
        if (to.path === '/login') { // 这就是跳出循环的关键
          next();
        } else {
          // isLogin ? next() : next("/login");
          next('/login');
        }
        localStorage.removeItem('isLoginFlag');
      }
    }).catch((res) => {
      const isLogin = !!localStorage.isLoginFlag;
      if (to.path === '/login') { // 这就是跳出循环的关键
        next();
      } else {
        isLogin ? next() : next('/login');
      }
      localStorage.removeItem('isLoginFlag');
    });
  } else {
    next();
  }
});

export default Router;
