<template>
  <div
    v-if="showTag"
    class="avue-tags"
  >
    <!-- tag盒子 -->
    <div
      ref="tagBox"
      class="tags-box"
    >
      <el-tabs
        v-model="active"
        class="tabs-menu-top"
        type="card"
        @tab-click="openTag"
        @edit="menuTag"
      >
        <el-tab-pane
          v-for="item in tagList"
          :key="item.value"
          :closable="item.label!='首页'"
          :label="item.label"
          :name="item.value"
        />
      </el-tabs>
      <div class="person_operate">
        <el-tooltip
          v-if="tagList.length > 1"
          content="清除其它页面"
          @click.native="closeOthersTags"
        >
          <i
            style="cursor: pointer;font-size: 17.5px;transform: rotate(180deg)"
            class="el-icon-brush"
          />
        </el-tooltip>
        <div
          class="reset_pass_panel panel-item"
          @click="opendialogVisible()"
        >
          <i class="iconfont">&#xe604;</i><span>修改密码</span>
        </div>
        <el-tooltip
          class="panel-item item"
          effect="dark"
          content="退出系統"
          placement="top-end"
        >
          <div
            class="layout_panel"
            @click="logout"
          >
            <i class="iconfont">&#xe605;</i><span>注销</span>
          </div>
        </el-tooltip>
        <el-form class="panel-item">
          <el-form-item prop="selectCom">
            <!-- {{brandInfo}} -->
            <el-select
              v-model="computedCurBrandId"
              clearable
              placeholder="请选择品牌"
              style="width: 145px;"
              filterable
              @change="changeBrand"
            >
              <el-option
                v-for="item in brandList"
                :key="item.nbrandId"
                :label="item.sbrandName"
                :value="item.nbrandId"
              />
              <i
                slot="prefix"
                class="iconfont icongongsiicon"
                style="color:#CCCCCC"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <!-- <el-form class="panel-item">
          <el-form-item prop="selectCom">
            <el-select
              v-model="comValue"
              clearable
              placeholder="请选择公司"
              style="width: 175px;"
              filterable
              :filter-method="dataFilter"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="`${item.sifsContract}  ${item.sshortName}`"
                :value="item.scomBm"
              />
              <i
                slot="prefix"
                class="iconfont icongongsiicon"
                style="color:#CCCCCC"
              />
            </el-select>
          </el-form-item>
        </el-form> -->
        <div class="person_img">
          <img :src="userInfo.sheadImg?userInfo.sheadImg:require('@/assets/images/user/user_img.jpg')">
          <!--<img src="@/assets/images/order/timg.jpg"/></div>-->
        </div>
        <!--<el-dropdown class="tags-menu pull-right">-->
        <!--<el-button type="primary"-->
        <!--size="mini"-->
        <!--style="background:#28CCCD;border-color:#28CCCD">-->
        <!--更多-->
        <!--<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--</el-button>-->
        <!--<el-dropdown-menu slot="dropdown">-->
        <!--<el-dropdown-item @click.native="closeOthersTags">关闭其他</el-dropdown-item>-->
        <!--<el-dropdown-item @click.native="closeAllTags">关闭全部</el-dropdown-item>-->
        <!--</el-dropdown-menu>-->
        <!--</el-dropdown>-->
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="520px"
      class="updatePassDiaPanel"
      :close-on-click-modal="false"
    >
      <el-form
        ref="updatePassForm"
        :model="updatePassForm"
        :rules="rules"
        label-width="110px"
      >
        <el-form-item
          label="旧密码"
          prop="oldPassword"
        >
          <el-input
            v-model="updatePassForm.oldPassword"
            type="password"
            placeholder="请输入旧密码"
          />
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="newPassword"
        >
          <el-input
            v-model="updatePassForm.newPassword"
            type="password"
            placeholder="请输入新密码"
          />
        </el-form-item>
        <el-form-item
          label="确认新密码"
          prop="newPassword2"
        >
          <el-input
            v-model="updatePassForm.newPassword2"
            type="password"
            placeholder="请确认新密码"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <z-button
          type="primary"
          plain
          margin="0 20px"
          @click="push('updatePassForm')"
        >
          确 定
        </z-button>
        <z-button
          type="primary"
          plain
          margin="0 20px"
          class="closeDiaBtn"
          @click="closepassword()"
        >
          取 消
        </z-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import store from '../../store';
import {
  getComListByUsername, changeCombm, updatePassword, apiChangeBrand,
} from '../../api/user';

export default {
  name: 'Tags',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.updatePassForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      // 当前查看的品牌
      curBrandId: null,
      comValue: '',
      updatePassForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      },
      // options: [],
      // optionsCopy: [],
      active: '',
      showTag: true,
      rules: {
        oldPassword: [
          { required: true, message: '旧密码不能为空！', trigger: 'blur' },
        ],
        newPassword2: [
          { required: true, message: '重复密码不能为空！', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '密码不能为空！', trigger: 'blur' },
          {
            min: 6,
            max: 25,
            message: '请您输入6-25位的密码！',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    tag() {
      this.setActive();
    },
    // comValue(item) {
    //   if (item != this.userInfo.scomBm && item) {
    //     this.changComBm();
    //   }
    // },
  },
  created() {},
  mounted() {
    this.checkTagList();
    this.setActive();
    // this.loadSelectCom();
  },
  computed: {
    ...mapGetters(['tagWel', 'tagList', 'tag', 'userInfo', 'brandInfo']),
    ...mapState({
      // showTag: state => state.common.showTag,
    }),
    brandList() {
      return this.userInfo.brands;
    },
    // 当前品牌id计算属性
    computedCurBrandId: {
      get() {
        return this.curBrandId || this.brandInfo.nbrandId;
      },
      set(value) {
        this.curBrandId = value;
      },
    },
    tagLen() {
      return this.tagList.length || 0;
    },
  },
  methods: {
    opendialogVisible() {
      this.dialogVisible = true;
      this.updatePassForm = {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      };
    },
    closepassword() {
      this.dialogVisible = false;
    },
    verifyPassword(val) {
      // ~`!@#$%^&*()-_+={[}]|\:;<,>.?/'" 允许输入的特殊符号

      // 6-16个字符
      if (val.length < 6) return false;
      if (val.length > 16) return false;

      // 不允许纯字母
      const patt1 = /^[a-zA-Z]+$/;
      if (patt1.test(val)) return false;

      // 不允许纯数字
      const patt2 = /^[0-9]+$/;
      if (patt2.test(val)) return false;

      // 至少有字母（字母大小写均可）
      const patt3 = /[a-zA-Z]/;
      if (!patt3.test(val)) return false;

      // 至少有数字
      const patt4 = /[0-9]/;
      if (!patt4.test(val)) return false;

      // 允许指定符号，不允许非法字符
      const patt5 = /^[a-zA-Z0-9~`!@#\$%\^&\*\(\)\-_\+=\{\[\}\]\|\\:;<,>.\?\/'"]+$/;
      return patt5.test(val);
    },
    push(formName) {
      if (!this.verifyPassword(this.updatePassForm.newPassword2) || !this.verifyPassword(this.updatePassForm.newPassword)) {
        this.$message({
          message: '1、6-16个字符2、至少字母+数字（字母大小写均可）；3、允许指定符号，不允许非法字符4、不允许纯数字或纯字母~',
          type: 'warning',
          duration: 2500,
        });
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassword(this.updatePassForm).then((response) => {
            this.$message.success('修改密码成功！');
            this.dialogVisible = false;
          }).catch((e) => {
            this.$message.error(e.message);
          });
        }
      });
    },
    // 添加首页
    checkTagList() {
      if (this.tagList.length === 1 && this.tagList[0].label != '首页') {
        const tag = this.tagList[0];
        store.commit('DEL_ALL_TAG');
        store.commit('ADD_TAG', {
          label: '首页',
          comName: 'home',
          value: '/home',
          params: {},
          query: {},
          group: [],
        });
        store.commit('ADD_TAG', tag);
      }
    },
    // 更换品牌域
    changeBrand(id) {
      apiChangeBrand({ brandIs: id }).then((response) => {
        window.location.reload();
      });
    },
    // changComBm() {
    //   changeCombm(this.comValue).then(() => {
    //     window.location.reload();
    //   });
    // },
    // dataFilter(val) {
    //   this.value = val;
    //   if (val) { // val存在
    //     this.options = this.optionsCopy.filter((item) => {
    //       if (!!~item.sifsContract.indexOf(val) || !!~item.sifsContract.toUpperCase().indexOf(val.toUpperCase()) || !!~item.sshortName.indexOf(val) || !!~item.sshortName.toUpperCase().indexOf(val.toUpperCase())) {
    //         return true;
    //       }
    //     });
    //   } else { // val为空时，还原数组
    //     this.options = this.optionsCopy;
    //   }
    // },
    // loadSelectCom() {
    //   this.$nextTick(() => {
    //     getComListByUsername(this.userInfo.suserCode).then((response) => {
    //       this.options = response;
    //       this.comValue = this.userInfo.scomBm;
    //       this.optionsCopy = this.options;
    //     });
    //   });
    // },
    // 激活当前选项
    setActive() {
      this.active = this.tag.value;
    },
    logout() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' });
          localStorage.removeItem('isLoginFlag');
        });
      });
    },
    menuTag(value, action) {
      if (action === 'remove') {
        let { tag, key } = this.findTag(value);
        this.$store.commit('DEL_TAG', tag);
        if (tag.value === this.tag.value) {
          tag = this.tagList[key === 0 ? key : key - 1]; // 如果关闭本标签让前推一个
          this.openTag(tag);
        }
      }
    },
    openTag(item) {
      let tag;
      if (item.name) {
        tag = this.findTag(item.name).tag;
      } else {
        tag = item;
      }
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          name: tag.label,
          src: tag.value,
        }),
        query: tag.query,
      });
    },
    closeOthersTags() {
      this.$store.commit('DEL_TAG_OTHER');
    },
    findTag(value) {
      let tag;
      let
        key;
      this.tagList.map((item, index) => {
        if (item.value === value) {
          tag = item;
          key = index;
        }
      });
      return { tag, key };
    },
    closeAllTags() {
      this.$store.commit('DEL_ALL_TAG');
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          src: this.tagWel.value,
        }),
        query: this.tagWel.query,
      });
    },
  },
};

</script>
<style lang="scss">
.tags-box {
  display: flex;

  .tabs-menu-top {
    flex: 1;
    overflow: hidden;
  }

  .person_operate {
    flex: none;
    display: inline-flex;
    height: 40px;
    margin: 1px 26px 0 0;
    color: #666;
    font-size: 14px;
    align-items: center;

    .panel-item {
      margin-left: 30px;

      i {
        color: #999;
      }
    }

    .el-form-item {
      margin: 0;
    }

    .el-input__inner {
      height: 28px;
      line-height: 28px;
    }

    .person_img {
      height: 34px;
      margin-left: 12px;

      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }
    }

    .reset_pass_panel {
      margin-left: 26px;
      cursor: pointer;

      i {
        margin-right: 6px;
        font-size: 14px;
      }
    }

    .layout_panel {
      margin-left: 30px;
      cursor: pointer;

      i {
        margin-right: 6px;
        font-size: 14px;
      }
    }
  }
}

.tabs-menu-top {
  margin: 9px 30px 0 20px;

  .el-tabs__header {
    margin: 0;
    padding-bottom: 0;
    border-bottom: none;

    .el-tabs__nav-wrap {
      box-shadow: none;

      .el-tabs__nav-scroll {
        padding: 0 10px 20px;
      }

      .el-tabs__nav {
        height: 30px;
        border: none;

        .el-tabs__item {

          margin: 0 3px;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: normal;
          border: none;
          border-radius: 4px;
          color: #19b0b0;
          padding: 0 24px !important;
          overflow: hidden;
          margin: 0 20px 0 0;
          transition: none;

          .el-icon-close {
            position: absolute;
            width: 25px;
            height: 25px;
            padding: 9px 3px 0 0;
            font-size: 12px;
            font-weight: 600;
            color: #28cccc;
            text-align: center;
            top: -10px;
            right: -6px;
            opacity: 0;
            box-sizing: border-box;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            transition: opacity .2s;
            z-index: 999;

            &:hover {
              background: none;
            }

            &:before {
              transform: scale(.8);
            }
          }

          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid rgba(25, 176, 176, 0.50);
          }

          &.is-active {
            // border: none;
            color: #fff;
            // font-weight: bold;
            box-shadow: 0px 2px 10px 3px rgba(85, 195, 198, 0.3);
            background: linear-gradient(to right, #28CCCC, #73E2E2);

            .el-icon-close {
              opacity: 1;
              background: #ffff;
            }

            &:hover {
              color: #fff;
            }

            &:after {
              display: none;
            }
          }

          &:hover {
            .el-icon-close {
              opacity: 1;
            }
          }

        }
      }

      .el-tabs__nav-prev,
      .el-tabs__nav-next {
        line-height: 30px;
      }

      &.is-scrollable {
        margin-left: 10px;
      }

    }
  }
}

.updatePassDiaPanel {
  .closeDiaBtn:hover {
    background: white !important;
    color: #28cccc !important;
    border-color: #28cccc !important;
  }

  .el-form-item__label {
    width: 96px !important;
  }
}

</style>
