import Layout from '@/views/index/';

export default [{
  path: '/',
  component: Layout,
  // redirect: '/wel/index',
  children: [{
    path: 'home',
    name: '首页',
    meta: { keepAlive: true, comName: 'HomeView' },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/HomeView'),
  }],
},
{
  path: '/demo',
  component: Layout,
  children: [{
    path: 'demo1',
    name: '模板1',
    meta: { keepAlive: true, comName: 'DemoPage' },
    component: () => import(/* webpackChunkName: "DemoPage" */ '@/views/demo/DemoPage'),
  }],
},
{
  path: '/message',
  component: Layout,
  children: [{
    path: 'articleManage',
    name: '文章管理',
    meta: { keepAlive: true, comName: 'ArticleManage' },
    component: () => import(/* webpackChunkName: "ArticleManage" */ '@/views/message/articleManage'),
  }],
},
{
  path: '/video',
  component: Layout,
  children: [{
    path: 'videoManage',
    name: '视频管理',
    meta: { keepAlive: true, comName: 'VideoManage' },
    component: () => import(/* webpackChunkName: "VideoManage" */ '@/views/video/videoManage'),
  }],
},
{
  path: '/messreply',
  component: Layout,
  children: [{
    path: 'messageBoard',
    name: '留言板',
    meta: { keepAlive: true, comName: 'MessageBoard' },
    component: () => import(/* webpackChunkName: "MessageBoard" */ '@/views/messreply/messageBoard'),
  },
  ],
},
{
  path: '/report',
  component: Layout,
  children: [{
    path: 'center',
    name: '数据中心',
    meta: { keepAlive: true, comName: 'ReportCenter' },
    component: () => import(/* webpackChunkName: "ReportCenter" */ '@/views/report/ReportCenter'),
  },
  ],
},
{
  path: '/poster',
  component: Layout,
  children: [{
    path: 'posterManage',
    name: '海报管理',
    meta: { keepAlive: true, comName: 'PosterManage' },
    component: () => import(/* webpackChunkName: "PosterManage" */ '@/views/poster/posterManage'),
  }],
}
];
