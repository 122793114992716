<template>
  <div class="menu-wrapper">
    <template v-for="item in menus">
      <el-menu-item
        v-if="validatenull(item[childrenKey]) && vaildRoles(item)"
        :key="item[labelKey]"
        :index="item[pathKey]"
        :class="{'is-active':vaildAvtive(item)}"
        @click="open(item)"
      >
        <i :class="item[iconKey]" />
        <span slot="title">{{ item[labelKey] }}</span>
      </el-menu-item>
      <el-submenu
        v-else-if="!validatenull(item[childrenKey])&&vaildRoles(item)"
        :key="item[labelKey]"
        :index="item[pathKey]"
      >
        <template slot="title">
          <i :class="item[iconKey]" />
          <span
            slot="title"
            :class="{'el-menu--display':collapse&&first}"
          >{{ item[labelKey] }}</span>
        </template>
        <template v-for="(child,cindex) in item[childrenKey]">
          <el-menu-item
            v-if="validatenull(child[childrenKey])"
            :key="child[labelKey]"
            :index="child[pathKey],cindex"
            :class="{'is-active':vaildAvtive(child)}"
            @click="open(child)"
          >
            <i :class="child[iconKey]" />
            <span slot="title">{{ child[labelKey] }}</span>
          </el-menu-item>
          <el-submenu
            v-else-if="!validatenull(child[childrenKey])&&vaildRoles(child)"
            :key="child[labelKey]"
            :class="{'is-active':vaildAvtive(child)}"
            :index="child[pathKey]"
          >
            <template slot="title">
              <i :class="child[iconKey]" />
              <span slot="title">{{ child[labelKey] }}</span>
            </template>
            <template v-for="(item,cindex) in child[childrenKey]">
              <el-menu-item
                v-if="validatenull(item[childrenKey])"
                :key="item[labelKey]"
                :index="item[pathKey],cindex"
                :class="{'is-active':vaildAvtive(item)}"
                @click="open(item)"
              >
                <i :class="item[iconKey]" />
                <span slot="title">{{ item[labelKey] }}</span>
              </el-menu-item>
            </template>
            <!--<template v-for="(child,cindex) in item[childrenKey]">-->
            <!---->
            <!--</template>-->
          </el-submenu>
          <!--<sidebar-item v-else-->
          <!--:menu="[child]"-->
          <!--:key="cindex"-->
          <!--:props="props"-->
          <!--:screen="screen"-->
          <!--:collapse="collapse"></sidebar-item>-->
        </template>
      </el-submenu>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { validatenull } from '@/assets/js/validate';
import config from './config.js';
import { checkNull } from '@/assets/js/util';
import { getUserInfo, loginByUsername, logout } from '@/api/login';

export default {
  name: 'SidebarItem',
  props: {
    screen: {
      type: Number,
    },
    first: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    collapse: {
      type: Boolean,
    },
  },
  data() {
    return {
      config,
      menus: [
      // {
      //   id: 178,
      //   ssurl: '1',
      //   norder: 1,
      //   stype: '1',
      //   sremark: '1',
      //   splantNo: 'mall',
      //   component: 'views/demo',
      //   name: '菜单1',
      //   path: '1',
      //   parentId: null,
      //   icon: 'el-icon-document',
      //   children: [{
      //     id: 184,
      //     ssurl: '',
      //     norder: 2,
      //     stype: '2',
      //     sremark: '',
      //     splantNo: 'mall',
      //     children: null,
      //     component: 'views/demo/DemoPage',
      //     name: '模板1',
      //     path: '/demo/demo1',
      //     parentId: 178,
      //     icon: '',
      //   }],
      // },
      // {
      //   id: 270,
      //   ssurl: '',
      //   norder: 4,
      //   stype: '1',
      //   sremark: '',
      //   splantNo: 'mall',
      //   component: 'views/message',
      //   name: '菜单2',
      //   path: '/message',
      //   parentId: null,
      //   icon: 'el-icon-document',
      //   children: [{
      //     id: 272,
      //     ssurl: 'a_getMallArticleList;a_updateMallArticleInfo;a_deleteMallArticles;a_insertMallArticle;a_getArticleClassifyList;a_updateArticleClassifyInfo;a_deleteArticleClassifyByIds;a_insertArticleClassify;a_findMallArticleByTid',
      //     norder: 6,
      //     stype: '2',
      //     sremark: '',
      //     splantNo: 'mall',
      //     children: null,
      //     component: 'views/message/articleManage',
      //     name: '文章管理',
      //     path: '/message/articleManage',
      //     parentId: 270,
      //     icon: '',
      //   }],
      // },
        {
          id: 280,
          ssurl: '',
          norder: 5,
          stype: '5',
          sremark: '',
          splantNo: 'mall',
          component: 'views/video',
          name: '资讯发布',
          path: '/video',
          parentId: null,
          icon: 'iconfont shujubaobiao p-r-6',
          children: [{
            id: 275,
            ssurl: '',
            norder: 6,
            stype: '6',
            sremark: '',
            splantNo: 'mall',
            children: null,
            component: 'views/video/videoManage',
            name: '视频管理',
            path: '/video/videoManage',
            parentId: 280,
            icon: '',
          },
          {
            id: 276,
            ssurl: '',
            norder: 7,
            stype: '7',
            sremark: '',
            splantNo: 'mall',
            children: null,
            component: 'views/message/articleManage',
            name: '文章管理',
            path: '/message/articleManage',
            parentId: 280,
            icon: '',
          },
          {
            id: 277,
            ssurl: '',
            norder: 7,
            stype: '7',
            sremark: '',
            splantNo: 'mall',
            children: null,
            component: 'views/poster/posterManage',
            name: '海报管理',
            path: '/poster/posterManage',
            parentId: 280,
            icon: '',
          },
          ],
        },
        {
          id: 281,
          ssurl: '',
          norder: 6,
          stype: '6',
          sremark: '',
          splantNo: 'mall',
          component: 'views/messreply',
          name: '留言回复',
          path: '/messreply',
          parentId: null,
          icon: 'iconfont liuyanhuifu p-r-6',
          children: [{
            id: 276,
            ssurl: '',
            norder: 6,
            stype: '6',
            sremark: '',
            splantNo: 'mall',
            children: null,
            component: 'views/messreply/messageBoard',
            name: '留言板',
            path: '/messreply/messageBoard',
            parentId: 281,
            icon: '',
          }],
        },
        {
          id: 282,
          ssurl: '',
          norder: 7,
          stype: '7',
          sremark: '',
          splantNo: 'mall',
          component: 'views/report',
          name: '数据报表',
          path: '/report',
          parentId: null,
          icon: 'iconfont shujubaobiao p-r-6',
          children: [{
            id: 277,
            ssurl: '',
            norder: 8,
            stype: '8',
            sremark: '',
            splantNo: 'mall',
            children: null,
            component: 'views/report/center',
            name: '数据中心',
            path: '/report/center',
            parentId: 282,
            icon: '',
          }],
        },
      ],
    };
  },
  mounted() {
    // const time = setInterval((res) => {
    //   if (!checkNull(this.userInfo)) {
    //     this.menus = this.userInfo.menus;
    //     if (this.userInfo.menus) this.initMenu(this.userInfo.menus);
    //   } else {
    //     clearInterval(time);
    //   }
    // }, 100);
    this.initMenu(this.menus);
  },
  beforeMount() {

  },
  computed: {
    ...mapGetters(['roles', 'userInfo']),
    labelKey() { return this.props.label || this.config.propsDefault.label; },
    pathKey() { return this.props.path || this.config.propsDefault.path; },
    iconKey() { return this.props.icon || this.config.propsDefault.icon; },
    childrenKey() { return this.props.children || this.config.propsDefault.children; },
    nowTagValue() { return this.$router.$avueRouter.getValue(this.$route); },
  },
  methods: {
    open1(item) {
      console.log('item1', item);
    },
    vaildAvtive(item) {
      const groupFlag = (item.group || []).some((ele) => this.$route.path.includes(ele));
      return this.nowTagValue === item[this.pathKey] || groupFlag;
    },
    vaildRoles(item) {
      item.meta = item.meta || {};
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true;
    },
    validatenull(val) {
      return validatenull(val);
    },
    open(item) {
      if (this.screen <= 1) this.$store.commit('SET_COLLAPSE');
      this.$router.$avueRouter.group = item.group;
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          name: item[this.labelKey],
          src: item[this.pathKey],
        }),
        query: item.query,
      });
    },
    initMenu(menus) {
      if (menus) {
        for (let i = 0; i < menus.length; i++) {
          const menu = menus[i];
          menu.label = menu.name;
          menu.sort = 0;
          if (!menu.children) {
            menu.children = [];
          }
          this.initMenu(menu.children);
        }
      }
    },
  },
};

</script>
<style scoped>
  .p-r-6{
    padding-right: 6px;
  }
</style>
