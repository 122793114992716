import {
  Message,
} from 'element-ui';
import { removeToken, setToken } from '@/assets/js/auth';
import { getStore, setStore } from '@/assets/js/store';
import { isURL } from '@/assets/js/validate';
import { getUserInfo, loginByUsername, logout } from '@/api/login';
import { deepClone, encryption } from '@/assets/js/util';
import webiste from '@/const/website';
import { GetMenu } from '@/api/menu';

function addPath(ele, first) {
  const propsConfig = webiste.menu.props;
  const propsDefault = {
    label: propsConfig.label || 'label',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children',
  };
  const isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0;
  if (!isChild && first) {
    ele[propsDefault.path] = `${ele[propsDefault.path]}/index`;
    return;
  }
  ele[propsDefault.children].forEach((child) => {
    if (!isURL(child[propsDefault.path])) {
      child[propsDefault.path] = `${ele[propsDefault.path]}/${child[propsDefault.path] ? child[propsDefault.path] : 'index'}`;
    }
    addPath(child);
  });
}

const user = {
  state: {
    userInfo: {},
    permissions: {},
    roles: [],
    pkid: '',
    menu: getStore({
      name: 'menu',
    }) || [],
    menuAll: [],
    access_token: getStore({
      name: 'access_token',
    }) || '',
    sessionid: getStore({
      name: 'sessionid',
    }) || '',
    refresh_token: getStore({
      name: 'refresh_token',
    }) || '',
  },
  getters: {
    brandInfo: (state) => state.userInfo.brandInfo || {},
  },
  actions: {

    // 根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      // const user = encryption({
      //     data: userInfo,
      //     key: '',
      //     param: ['password']
      // })
      return new Promise((resolve, reject) => {
        loginByUsername(userInfo.userName, userInfo.password, userInfo.comBm).then((response) => {
          if (response) {
            // const { data } = response;
            // setToken(data.sessionid)
            // commit('SET_ACCESS_TOKEN', data.sessionid)
            // commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('SET_USERIFNO', response);
            commit('SET_MENU_ALL', response.menus || []);
            commit('SET_PERMISSIONS', response.power || []);
            commit('CLEAR_LOCK');
            resolve(response);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((response) => {
          const data = response || {};
          commit('SET_USERIFNO', data);
          commit('SET_ROLES', data.roles || []);
          commit('SET_PERMISSIONS', data.permissions || []);
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 刷新token
    // RefeshToken({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     logout().then(() => {
    //       commit('SET_TOKEN', new Date().getTime());
    //       // setToken();
    //       resolve();
    //     }).catch((error) => {
    //       reject(error);
    //     });
    //   });
    // },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_MENU', []);
          commit('SET_PERMISSIONS', []);
          // commit('SET_USER_INFO', {});
          commit('SET_USERIFNO', {});
          commit('SET_ACCESS_TOKEN', '');
          commit('SET_REFRESH_TOKEN', '111');
          commit('SET_ROLES', []);
          commit('DEL_ALL_TAG');
          commit('CLEAR_LOCK');
          removeToken();
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    },
    // 注销session
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_MENU', []);
        commit('SET_PERMISSIONS', []);
        // commit('SET_USER_INFO', {});
        commit('SET_USERIFNO', {});
        commit('SET_ACCESS_TOKEN', '');
        commit('SET_REFRESH_TOKEN', '');
        commit('SET_ROLES', []);
        commit('DEL_ALL_TAG');
        commit('CLEAR_LOCK');
        removeToken();
        resolve();
      });
    },
    // 获取系统菜单
    GetMenu({
      commit,
    }) {
      return new Promise((resolve) => {
        GetMenu().then((response) => {
          // const { data } = res.data;
          const menu = deepClone(response);
          menu.forEach((ele) => {
            addPath(ele);
          });
          commit('SET_MENU', menu);
          resolve(menu);
        });
      });
    },

  },
  mutations: {
    SET_ACCESS_TOKEN: (state, sessionid) => {
      state.sessionid = sessionid;
      setStore({
        name: 'sessionid',
        content: state.sessionid,
        type: 'session',
      });
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken;
      setStore({
        name: 'refresh_token',
        content: state.refresh_token,
        type: 'session',
      });
    },
    SET_USERIFNO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_MENU: (state, menu) => {
      state.menu = menu;
      setStore({
        name: 'menu',
        content: state.menu,
        type: 'session',
      });
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {};
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i]] = true;
      }
      state.permissions = list;
    },
    SET_PKID: (state, pkid) => {
      state.pkid = pkid;
    },
  },

};
export default user;
