<template>
  <div class="avue-sidebar">
    <logo />
    <el-scrollbar style="height:100%;margin-top: 5px">
      <el-menu
        unique-opened
        :default-active="nowTagValue"
        mode="vertical"
        :show-timeout="200"
        background-color="#20222a"
        text-color="rgba(255,255,255,0.7)"
        :collapse="keyCollapse"
      >
        <sidebar-item
          :menu="menu"
          first
          :screen="screen"
          :props="website.menu.props"
          :collapse="keyCollapse"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import logo from '../logo';
import sidebarItem from './sidebarItem';

export default {
  name: 'Sidebar',
  components: { sidebarItem, logo },
  data() {
    return {};
  },
  created() {
    // const data = [
    //   {
    //     id: 1000,
    //     icon: 'el-icon-s-home',
    //     name: '首页',
    //     spread: false,
    //     path: '/wel',
    //     component: 'views/index/wel',
    //     authority: null,
    //     redirect: null,
    //     keepAlive: '0',
    //     code: null,
    //     type: '0',
    //     label: '首页',
    //     sort: 0,
    //     children: [],
    //   },
    //   {
    //     authority: null,
    //     children: [
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productKind',
    //         icon: 'el-icon-s-shop',
    //         id: 2100,
    //         keepAlive: '0',
    //         label: '产品分类',
    //         name: '产品分类',
    //         parentId: 2000,
    //         path: '/product/productKind',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productBrand',
    //         icon: 'el-icon-s-cooperation',
    //         id: 2200,
    //         keepAlive: '0',
    //         label: '产品品牌',
    //         name: '产品品牌',
    //         parentId: 2000,
    //         path: '/product/productBrand',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productSeries',
    //         icon: 'el-icon-s-management',
    //         id: 2300,
    //         keepAlive: '0',
    //         label: '产品系列',
    //         name: '产品系列',
    //         parentId: 2000,
    //         path: '/product/productSeries',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productSpecification',
    //         icon: 'el-icon-s-order',
    //         id: 2400,
    //         keepAlive: '0',
    //         label: '产品规格',
    //         name: '产品规格',
    //         parentId: 2000,
    //         path: '/product/productSpecification',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productManage',
    //         icon: 'el-icon-s-opportunity',
    //         id: 2500,
    //         keepAlive: '0',
    //         label: '产品列表',
    //         name: '产品列表',
    //         parentId: 2000,
    //         path: '/product/productManage',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productGallery',
    //         icon: 'el-icon-picture',
    //         id: 2600,
    //         keepAlive: '0',
    //         label: '产品图库',
    //         name: '产品图库',
    //         parentId: 2000,
    //         path: '/product/productGallery',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/productSamplebank',
    //         icon: 'el-icon-s-open',
    //         id: 2700,
    //         keepAlive: '0',
    //         label: '素材管理',
    //         name: '素材管理',
    //         parentId: 2000,
    //         path: '/product/productSamplebank',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: 'views/product/priceAdjustment',
    //         icon: 'el-icon-discount',
    //         id: 2800,
    //         keepAlive: '0',
    //         label: '定时调价',
    //         name: '定时调价',
    //         parentId: 2000,
    //         path: '/product/priceAdjustment',
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: '0',
    //       },
    //     ],
    //     code: null,
    //     component: 'Layout',
    //     icon: 'el-icon-s-goods',
    //     id: 2000,
    //     keepAlive: '0',
    //     label: '产品管理',
    //     name: '产品管理',
    //     parentId: -1,
    //     path: '/users',
    //     redirect: null,
    //     sort: 0,
    //     spread: false,
    //     type: '0',
    //   },
    // ];
    // this.$router.$avueRouter.formatRoutes(data, true);
    // console.log('this.menu', this.menu);
    // var data = [
    //   {
    //     "id":1000,
    //     "icon":"icon-quanxianguanli",
    //     "name":"首页",
    //     "spread":false,
    //     "path":"/wel",
    //     "component":"views/index/wel",
    //     "authority":null,
    //     "redirect":null,
    //     "keepAlive":"0",
    //     "code":null,
    //     "type":"0",
    //     "label":"首页",
    //     "sort":0
    //   },
    //   {
    //     authority: null,
    //     children: [
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/product/productManage",
    //         icon: "icon-yonghuguanli",
    //         id: 2100,
    //         keepAlive: "0",
    //         label: "产品管理",
    //         name: "产品管理",
    //         parentId: 1000,
    //         path: "/product/productManage",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/product/productKind",
    //         icon: "icon-yonghuguanli",
    //         id: 2200,
    //         keepAlive: "0",
    //         label: "商品分类",
    //         name: "商品分类",
    //         parentId: 2000,
    //         path: "/product/productKind",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/product/productBrand",
    //         icon: "icon-yonghuguanli",
    //         id: 2300,
    //         keepAlive: "0",
    //         label: "品牌管理",
    //         name: "品牌管理",
    //         parentId: 3000,
    //         path: "/product/productBrand",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/product/productSpecification",
    //         icon: "icon-yonghuguanli",
    //         id: 2400,
    //         keepAlive: "0",
    //         label: "规格名管理",
    //         name: "规格名管理",
    //         parentId: 4000,
    //         path: "/product/productSpecification",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/product/productSeries",
    //         icon: "icon-yonghuguanli",
    //         id: 2500,
    //         keepAlive: "0",
    //         label: "系列管理",
    //         name: "系列管理",
    //         parentId: 5000,
    //         path: "/product/productSeries",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //     ],
    //     code: null,
    //     component: "Layout",
    //     icon: "icon-quanxianguanli",
    //     id: 2000,
    //     keepAlive: "0",
    //     label: "商品管理",
    //     name: "商品管理",
    //     parentId: -1,
    //     path: "/users",
    //     redirect: null,
    //     sort: 0,
    //     spread: false,
    //     type: "0",
    //   },
    //   {
    //     authority: null,
    //     children: [
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/system/regionalManagement",
    //         icon: "icon-yonghuguanli",
    //         id: 2700,
    //         keepAlive: "0",
    //         label: "区域管理",
    //         name: "区域管理",
    //         parentId: 1000,
    //         path: "/system/regionalManagement",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/system/memberManagement",
    //         icon: "icon-yonghuguanli",
    //         id: 2800,
    //         keepAlive: "0",
    //         label: "人员管理",
    //         name: "人员管理",
    //         parentId: 2000,
    //         path: "/system/memberManagement",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/system/funcManagement",
    //         icon: "icon-yonghuguanli",
    //         id: 2900,
    //         keepAlive: "0",
    //         label: "功能管理",
    //         name: "功能管理",
    //         parentId: 3000,
    //         path: "/system/permissionManagement",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       },
    //       {
    //         authority: null,
    //         children: Array(0),
    //         code: null,
    //         component: "views/system/permissionManagement",
    //         icon: "icon-yonghuguanli",
    //         id: 3000,
    //         keepAlive: "0",
    //         label: "权限管理",
    //         name: "权限管理",
    //         parentId: 4000,
    //         path: "/system/permissionManagement",
    //         redirect: null,
    //         sort: 1,
    //         spread: false,
    //         type: "0"
    //       }
    //     ],
    //     code: null,
    //     component: "Layout",
    //     icon: "icon-quanxianguanli",
    //     id: 2600,
    //     keepAlive: "0",
    //     label: "系统配置",
    //     name: "系统配置",
    //     parentId: -1,
    //     path: "/users",
    //     redirect: null,
    //     sort: 0,
    //     spread: false,
    //     type: "0",
    //   }
    // ]
    // this.$router.$avueRouter.formatRoutes(data, true);
  },
  computed: {
    ...mapGetters(['website', 'menu', 'tag', 'keyCollapse', 'screen']),

    nowTagValue() { return this.$router.$avueRouter.getValue(this.$route); },
  },
  mounted() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
